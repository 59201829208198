export default (permissions) => {
  const permissionRoutes = [
    {
      permission: 'DashboardRead',
      route: 'dashboard'
    },
    {
      permission: 'LeadRead',
      route: 'gestao-leads'
    },
    {
      permission: 'SaleRead',
      route: 'vendas'
    },
    {
      permission: 'CouponRead',
      route: 'cupom'
    },
    {
      permission: 'ContractRead',
      route: 'contratos'
    },
    {
      permission: 'ProductRead',
      route: 'produtos'
    },
    {
      permission: 'CustomCheckoutRead',
      route: 'checkout'
    },
    {
      permission: 'CenterNotasRead',
      route: 'contratos'
    },
    {
      permission: 'LinkRead',
      route: 'central-de-notas'
    },
  ]

  const matchedRoute = permissionRoutes.find(item => {
    return permissions.some(el => el.name === item.permission);
  });

  return matchedRoute;
}
