<script setup>
import { computed } from "vue";

const props = defineProps({
  as: {
    type: String,
    default: "h1",
  },
  size: {
    type: String,
    default: "4xl",
  },
  weight: {
    type: String,
    default: "normal",
  },
  color: {
    type: String,
    default: "gray-500",
  },
});

const getCustomStyles = computed(() => {
  const colorStyle = `color: var(--${props.color})!important`;
  const fontWeightStyle = `font-weight: var(--weight-${props.weight})`;
  const styles = [colorStyle, fontWeightStyle];
  if (props.size) {
    styles.push(`
      font-size: var(--font-${props.size}) !important;line-height: 1.5em !important;
    `);
  }
  return styles;
});
</script>

<template>
  <component :is="as" :style="getCustomStyles">
    <slot />
  </component>
</template>

<style lang="scss">
h1 {
  font-size: var(--font-4-5xl);
  line-height: var(--spacing-10);
}

h2 {
  font-size: var(--font-3-5xl);
  line-height: var(--spacing-10);
}

h3 {
  font-size: var(--font-2xl);
  line-height: var(--spacing-8);
}

h4,
h5,
h6 {
  font-size: var(--font-base);
  line-height: var(--spacing-6);
}
</style>
