import Claim from "@/services/resources/Claim";
import router from '@/router/index'

const service = Claim.build();

export default {
    fetch_claims: ({ commit, dispatch, getters }, filters) => {
        dispatch('startLoading')
        let filter = { ...filters, url: '/list'  } 
        commit('set_claim_filters', )
        service.search(filter)
        .then(res => {
                commit('set_claims', res)
                commit('set_claim_filters', {
                    ...getters.get_claim_filters,
                    current_page: res.current_page,
                    last_page: res.last_page,
                    per_page: res.per_page,
                })
                dispatch('stopLoading')
            })
            .catch(e => {
                dispatch('stopLoading')
                console.log('error list_claims', e)
            })
    },
    answer_claim_admin: ({ commit, dispatch, getters }) => {
        dispatch('startLoading')
        service.update({ ...getters.get_claim, claim_id: getters.get_claim.id, type: "SELLER" }, `answer/${getters.get_claim.id}`)
        .then(res => {
            // commit('set_claim', res)
            dispatch('stopLoading')
        })
        .catch(e => {
            dispatch('stopLoading')
            console.log('error', e)
        })
    },
    close_claim_admin: ({ commit, dispatch, getters }) => {
        dispatch('startLoading')
        service.search({ url: `/close/${getters.get_claim.id}` })
        .then(res => {
            // commit('set_claim', res)
            dispatch('stopLoading')
        })
        .catch(e => {
            dispatch('stopLoading')
            console.log('error', e)
        })
    },
    refund_claim_admin: ({ commit, dispatch, getters }) => {
        dispatch('startLoading')
        service.create({ ...getters.get_claim, claim_id: getters.get_claim.id }, null, '/answer')
        .then(res => {
            // commit('set_claim', res)
            dispatch('stopLoading')
        })
        .catch(e => {
            dispatch('stopLoading')
            console.log('error', e)
        })
    },
}