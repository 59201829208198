<template>
  <BaseModal :name="id ? id : 'identity-validation'" :size="size" :title="`${$t('client.identity_verification')}`" @hidden="closeModal(step == 5)"
    @shown="openModal">
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <div class="container" v-if="!loading">
      <div class="custom-error p-3 mb-3" v-if="errorKycCelcoin.length && step !== 5">
        <div class="d-flex align-items-end mb-2">
          <img class="pr-2" src="@/assets/img/icons/warning-error.svg"/>
          <span class="custom-error-subtitle">{{ $t('client.pending') }}</span><br/>
        </div>
        <ul class="custom-ul" v-if="errorKycCelcoin.length === 1">
          <li class="custom-li mt-2" >
            <span class="custom-error-text">
              {{ $t('error_kyc.default') }}
            </span>
          </li>
        </ul>
        <ul class="custom-ul" v-if="errorKycCelcoin.length > 1">
          <!-- v-for="(erro_code, i) in errorKycCelcoin" :key="'err_kyc_'+i" -->
          <li class="custom-li mt-2">
            <span class="custom-error-text">
              <!-- {{ getErrorMsg(erro_code) }} -->
              {{ $t('error_kyc.default') }}
            </span>
          </li>
        </ul>
      </div>

      <div v-if="step == 1 && !no_cam.negado" data-anima="right">
        <h4 class="mb-4">{{ $t('client.required_to_make_verify') }}</h4>

        <ul class="requisitos">
          <li>
            <img src="@/assets/img/icons/warning-info.svg" />
            <p>{{ $t('client.warning_document_type') }}</p>
          </li>
          <li class="custom-mg-lf">
            <img src="@/assets/img/icons/validation-doc.svg" />
            <p> {{ $t('client.identity_with_photo_and_document') }} </p>
          </li>
          <li>
            <img src="@/assets/img/icons/validation-cam.svg" />
            <p>{{ $t('client.device_with_access_cam') }}</p>
          </li>
          <li>
            <img src="@/assets/img/icons/validation-light.svg" />
            <p>{{ $t('client.room_with_lighting') }}</p>
          </li>
        </ul>

        <div>
          <BaseButton variant="info2" class="btn-modal init" @click="nextStep">{{ $t('client.start_verification') }}
          </BaseButton>
        </div>
      </div>

      <div v-if="step == 2 && !no_cam.negado" data-anima="left">
        <div v-if="!tiraFoto">
          <h2>{{ $t('client.identity') }}</h2>
          <h4 class="mb-4">{{ $t('client.use_a_valid_document') }}</h4>

          <ul class="requisitos dicas">
            <li>
              <img src="@/assets/img/icons/mini-check.svg" />
              <p>{{ $t('client.original_size_without_edits') }}</p>
            </li>
            <li>
              <img src="@/assets/img/icons/mini-check.svg" />
              <p>{{ $t('client.colored_clear_bright_and_legible_images') }}</p>
            </li>
            <li>
              <img src="@/assets/img/icons/mini-x.svg" />
              <p>{{ $t('client.do_not_send_black_and_white_images') }}</p>
            </li>
            <li>
              <img src="@/assets/img/icons/mini-x.svg" />
              <p>{{ $t('client.do_not_send_expired_or_edited_documents') }}</p>
            </li>
          </ul>
        </div>

        <div v-if="tiraFoto">
          <h2>
            {{
              frente_verso == 1 ? $t('client.front_of_document') : $t('client.back_of_document')
            }}
          </h2>
          <h4 class="mb-4">
            {{ $t('client.add_a_photo') }}
            {{
              frente_verso == 1
              ? $t('client.from_the_front_of_document')
              : $t('client.from_the_back_of_document')
            }}
          </h4>
        </div>

        <ImageRecord v-if="tiraFoto" @close="getFoto" :isMac="isMac" />
        <div class="docs" v-if="!tiraFoto">
          <div class="doc-item" @click="tirarFoto(1)" v-if="!foto.frente">
            <img src="@/assets/img/icons/validation-doc.svg" />
            <span>{{ $t('client.front_of_document') }}</span>
          </div>
          <img :src="foto.frente" v-if="foto.frente" @click="tirarFoto(1)" class="foto-item" id="foto-item-1" />
          <b-tooltip target="foto-item-1" :title="$t('client.click_to_change')" />
          <div class="doc-item" @click="tirarFoto(2)" v-if="!foto.verso">
            <img src="@/assets/img/icons/validation-doc.svg" />
            <span>{{ $t('client.back_of_document') }}</span>
          </div>
          <img :src="foto.verso" v-if="foto.verso" @click="tirarFoto(2)" class="foto-item" id="foto-item-2" />
          <b-tooltip target="foto-item-2" :title="$t('client.click_to_change')" />
        </div>
      </div>

      <div v-if="step == 3 && !no_cam.negado" data-anima="left">
        <h2>{{ isMac ? $t('client.presentation_video') : $t('client.text_2') }}</h2>
        <h4>
          {{
            isMac
            ? $t('client.text_3')
            : $t('client.text_4')
          }}
        </h4>
        <p class="mb-4 mt-3" v-if="!isMac">
          {{ $t('client.text_5') }}
        </p>
        <p class="mb-4 mt-3" v-if="isMac">
          {{ $t('client.text_6') }}
        </p>

        <div class="dicas-container">
          <div class="dica-item">
            <img src="@/assets/img/icons/bone.svg" />
            <span>{{ $t('client.text_7') }}</span>
          </div>
          <div class="dica-item">
            <img src="@/assets/img/icons/oculos.svg" />
            <span>{{ $t('client.text_8') }}</span>
          </div>
          <div class="dica-item">
            <img src="@/assets/img/icons/filtros-foto.svg" />
            <span>{{ $t('client.text_9') }}</span>
          </div>
          <div class="dica-item">
            <img src="@/assets/img/icons/luz.svg" />
            <span>{{ $t('client.text_10') }}</span>
          </div>
        </div>
      </div>

      <div v-if="no_cam.negado" data-anima="top" class="no-cam">
        <img src="@/assets/img/icons/no_cam.svg" />

        <div v-if="no_cam.type == 'em uso'">
          <h2>{{ $t('client.text_11') }}</h2>

          <p>
            {{ $t('client.text_12') }}
          </p>
        </div>

        <div v-else>
          <h2>{{ $t('client.text_13') }}</h2>

          <p>
            {{ $t('client.text_14') }}
          </p>
        </div>

        <BaseButton variant="danger" class="btn-no_cam" @click="resetCam">{{ $t('client.text_15') }}</BaseButton>
      </div>

      <div v-if="step == 4 && !no_cam" data-anima="left" class="video-container">
        <VideoRecord v-if="!isMac" :poses="poses" :closeAll="closeAll" @confirm="confirmSubmitVideo"
          @update="fetchPoses" />
        <UploadVideo v-if="isMac" @confirm="confirmSubmitVideo" />
      </div>
      <div v-if="step == 5 && !no_cam" data-anima="top" class="no-cam">
        <img src="@/assets/img/icons/confirm-validation.svg" />
        <h2>{{ $t('client.text_16') }}</h2>

        <p>
          {{ $t('client.text_17') }}
        </p>

        <BaseButton variant="info2" class="btn-no_cam" @click="closeModal(true)">{{ $t('client.text_15') }}</BaseButton>
      </div>

      <div class="container-progress" v-show="step > 1 && !no_cam && step != 4 && step != 5 && !tiraFoto">
        <b-progress :value="progress_value" max="100" class="is-blue"></b-progress>
      </div>
    </div>
    <template v-slot:footer="{ }">
      <div>
        <BaseButton variant="link-info" v-show="step > 1 && !no_cam && step != 4 && step != 5 && !tiraFoto" class="mr-3"
          :disabled="loading" @click="previousStep">{{ $t('client.text_18') }}</BaseButton>

        <BaseButton variant="info2" v-show="step > 1 && !no_cam && step != 4 && step != 5 && !tiraFoto"
          :disabled="(step == 2 && (!foto.frente || !foto.verso)) || loading" @click="nextStep">{{
            step != 3
            ? $t('client.text_19')
            : isMac
              ? $t('client.text_20')
              : $t('client.text_21')
          }}</BaseButton>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import VideoRecord from "./VideoRecord.vue";
import UploadVideo from "./UploadVideo.vue";
import ImageRecord from "./ImageRecord.vue";
import { EventBus } from "@/main.js";
import { datadogLogs } from "@datadog/browser-logs";
import { mapGetters } from "vuex"
import errorKycCelcoin from "@/utils/errorKycCelcoin.js"

import UserService from "@/services/resources/UserService";
import UserSettingsService from "@/services/resources/UserSettingsService";
import ValidationService from "@/services/resources/ValidationService";

const serviceUser = UserService.build();
const serviceUserSettings = UserSettingsService.build();
const serviceValidation = ValidationService.build();


if (['production', 'staging'].includes(process.env.VUE_APP_ENVIRONMENT) && process.env.VUE_APP_LOG_STATUS == 'enabled') {
  datadogLogs.init({
    applicationId: "d7aa09b9-670c-4509-9c91-bfda49d83380",
    clientToken: "pubeabdfa088f28729b07bb5e263149bc5b",
    site: "datadoghq.com",
    service: "adm",
    env: "production",
    version: "1.0.0",
    sampleRate: 100,
    replaySampleRate: 100,
    defaultPrivacyLevel: "mask-user-input",
  });
}
export default {
  name: "IdentifyValidation",
  props: ['id'],
  data() {
    return {
      size: "md",
      loading: false,
      closeAll: false,
      progress_value: 15,
      poses: [],
      max: 100,
      no_cam: false,
      step: 1,
      preview: null,
      tiraFoto: false,
      frente_verso: 0,
      foto: {
        frente: null,
        verso: null,
      },
      max_steps: 5
    };
  },
  components: {
    VideoRecord,
    UploadVideo,
    ImageRecord,
  },
  computed: {
    ...mapGetters({
      kyc_personal_checklist: 'getKycPersonalChecklist',
      errorKycCelcoin: 'getErrorKycCelcoinPF'
    }),
    userId() {
      return this.$store.getters.getProfile.id;
    },
    isMac() {
      return window.localStorage.isMac == "true" ? 1 : 0;
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    },
    isWebView() {
      return window.isWebView == true;
    },
    userLogged() {
      return this.$store.getters.getProfile;
    },
    hasBothCompanyDocuments() {
      return (
        this.userLogged &&
        this.userLogged.company &&
        this.userLogged.company.documents &&
        this.userLogged.company.documents.length &&
        this.userLogged.company.documents.some(x => x.document_type === 'CONTRATO_SOCIAL' || x.document_type === null)
        // this.userLogged.company.documents.some(x => x.document_type === 'FATURAMENTO' || x.document_type === 'BALANCO')
      )
    },
  },
  methods: {
    getErrorMsg(code) {
      return errorKycCelcoin(code.slice(0, -3),this)
    },
    errorReturn() {
      this.$bvModal.hide("identity-validation")
      EventBus.$emit("incomplete")
      if (this.$route.path != "/minha-conta") this.$router.push("/minha-conta")
    },
    openModal() {
      this.loading = true
      const user = this.$store.state.user.user

      if (user.cpf_cnpj && user.email) {
        if ((!this.isHeaven && user.rg) || (this.isHeaven && (user.country === 'BR' && user.rg) || (user.country !== 'BR'))) {
          this.fetchPoses().then(res => { this.poses = res })
        } else this.errorReturn()
      } else this.errorReturn()
    },
    fetchPoses() {
      return new Promise((resolve) => {
        this.loading = true;
        serviceValidation
          .create()
          .then((response) => {
            this.loading = false;
            resolve(response);
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
            resolve(false);
          });
      });
    },
    closeModal(emit = false) {
      if(emit) this.$emit("close");
      this.$bvModal.hide("identity-validation");
      this.reset();
    },

    jsonToFormData(data) {
      const formData = new FormData();

      this.buildFormData(formData, data);
      return formData;
    },
    buildFormData(formData, data, parentKey) {
      if (
        data &&
        typeof data === "object" &&
        !(data instanceof Date) &&
        !(data instanceof File)
      ) {
        Object.keys(data).forEach((key) => {
          this.buildFormData(
            formData,
            data[key],
            parentKey ? `${parentKey}[${key}]` : key
          );
        });
      } else {
        const value = data == null ? "" : data;

        formData.append(parentKey, value);
      }
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = (error) => {
          reject(error);
        };
      });
    },

    confirmSubmitVideo(data) {
      this.loading = true;    

      const video = new File([data], "video", { type: data.type })

      const types = ['doc_front','doc_back','video']
      const promisses = []

      types.forEach(type => {
        let requestData = {           
          is_mac: this.isMac,
        };
        
        if(type === 'doc_front') requestData.doc_front = this.foto.frente
        if(type === 'doc_back') requestData.doc_back = this.foto.verso
        if(type === 'video') requestData.video = video
        if (['production', 'staging'].includes(process.env.VUE_APP_ENVIRONMENT) && process.env.VUE_APP_LOG_STATUS == 'enabled') {
          datadogLogs.logger.warn("🟡 Dados do Vídeo - 02", {
            name: "Enviado os dados do vídeo COM base64",
            JSON: JSON.stringify(requestData),
            requestData
          });
        }

        promisses.push(
          serviceValidation.create(this.jsonToFormData({...requestData, type}), "/upload")
        )
      });
      
      Promise.all(promisses)
        .then((r) => {
            this.nextStep();
            if (['production', 'staging'].includes(process.env.VUE_APP_ENVIRONMENT) && process.env.VUE_APP_LOG_STATUS == 'enabled') {
              datadogLogs.logger.warn("✅ Dados do Vídeo - SUCESSO", {
                name: "Vídeo enviado com sucesso",
                dados: r,
              });
            }
          })
          .catch((err) => {
            console.error(err);
            if (['production', 'staging'].includes(process.env.VUE_APP_ENVIRONMENT) && process.env.VUE_APP_LOG_STATUS == 'enabled') {
              datadogLogs.logger.warn("❌ Dados do Vídeo - ERRO", {
                name: "Não foi possível enviar os dados",
                dados: err,
              });
            }
          })
          .finally(() => {
            this.loading = false;
          });
    },
    saveImagesUser() {
      this.loading = true;
      let data = {
        images: [
          {
            name: "frente_doc",
            path: this.foto.frente,
          },
          {
            name: "verso_doc",
            path: this.foto.verso,
          },
        ],
        _method: "PUT",
      };

      serviceUser
        .create(data, this.userId)
        .catch((err) => {
          console.log(err);
          this.step == 2;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getFoto(data) {
      this.tiraFoto = false;
      if (this.frente_verso == 1) {
        this.foto.frente = data;
      } else if (this.frente_verso == 2) {
        this.foto.verso = data;
      }
    },
    tirarFoto(index) {
      this.frente_verso = index;
      this.tiraFoto = true;
      if (this.frente_verso == 1) {
        this.foto.frente = null;
      } else if (this.frente_verso == 2) {
        this.foto.verso = null;
      }
    },
    reset() {
      this.closeAll = true;
      this.step = 1;
      this.frente_verso = 0;
      this.foto = {
        frente: null,
        verso: null,
      };
      this.tiraFoto = false;
    },
    resetCam() {
      this.previousStep();
      this.no_cam = false;
    },
    nextStep() {
      if (this.isWebView) {
        window.flutter_inappwebview.callHandler('completeCheck');
      } else {
        if (
          this.step == 4 && 
          this.userLogged.company_id && 
          (this.$store.state.account_type === 'PJ' || this.$store.state.account_type === 'PENDING_PJ') && 
          !this.hasBothCompanyDocuments
        ) {
          this.$bvModal.show('modal-pj-account')
          this.closeModal();
        }
        this.step = this.step++ > this.max_steps ? this.max_steps : this.step++;
        this.progress_value += 15;
        if (this.step == 4) {
          this.size = "lg";
        } else {
          this.size = "md";
        }
      }
    },
    previousStep() {
      this.step--;
      this.progress_value -= 15;
    },
  },
  mounted() {
    if (/(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)) {
      window.localStorage.isMac = true;
    } else {
      window.localStorage.isMac = false;
    }
  },
  created() {
    EventBus.$on("videoPermission", (data) => {
      this.no_cam = data;
    });
  },
};
</script>

<style scoped>
.custom-mg-lf{
  margin-left: 1px
}
.custom-error {
  line-height: 14px;
  background-color: rgba(255, 158, 68, 0.10);
  border: solid 0;
  border-radius: 5px;
}

.custom-error-text {
  color: #141414;
  font-size: 13px;
  font-weight: 400;
}
.custom-error-subtitle {
  color: #FF9E44;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
}
ul.custom-ul {
  list-style-type: none;
  padding-left: 1em; 
}

li.custom-li::before {
  content: "-";
  display: inline-block;
  width:  0.8em;
  margin-left: -1em;
}
.container {
  padding: 0px 20px !important;
}

.btn-modal.init {
  margin-top: 30px;
  width: 100%;
}

p {
  color: #81858e;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  margin: 0;
}

h2 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 25px;
}

h4 {
  color: #2133d2;
  font-size: 14px;
  font-weight: 600;
}

.requisitos {
  margin-top: 40px;
  display: block;
}

.requisitos li {
  display: flex;
  align-items: center;
  gap: 20px;
}

.requisitos li+li {
  margin-top: 30px;
}

.dicas {
  margin-top: 0;
}

.dicas li+li {
  margin-top: 12px;
}

.docs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-top: 25px;
}

.doc-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
  background: #f7f7f7;
  border-radius: 5px;
  padding: 20px;
  cursor: pointer;
}

.foto-item {
  border-radius: 5px;
  cursor: pointer;
  width: 200px;
  height: 150px;
  object-fit: cover;
  background: #f7f7f7;
}

.doc-item img {
  filter: invert(50%) brightness(0);
}

.doc-item span {
  font-size: 13px;
}

.container-progress {
  max-width: 150px;
  margin: 40px 0 10px 0;
}

.progress {
  background: #f7f7f7;
  height: 7px;
  border-radius: 5px;
}

.dicas-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
}

.dica-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
  text-align: center;
}

.dica-item span {
  font-weight: 600;
  font-size: 14px;
}

.no-cam *,
.no-cam {
  margin: 0 auto;
  text-align: center;
}

.no-cam h2 {
  margin: 30px 0;
}

.no-cam .btn-no_cam {
  width: 60%;
  margin-top: 40px;
}
</style>