import Cookies from "js-cookie";
import AuthService from "@/services/resources/AuthService";
import AdminLoginService from "@/services/resources/AdminLoginService";
import store from "@/store/index";
import AuthServiceEnvios from "@/services/resources/AuthServiceEnvios";

export default {
  registerLogin(context, payload) {
    let access_token = "Bearer " + payload.access_token;
    Cookies.set("access_token", access_token, {
      expires: 365,
    });

    context.commit("authSuccess", access_token);
    context.dispatch("userRequest");
    context.dispatch("clubVerification");
    if (!store.state.app.isClient) context.dispatch("emailVerification");
  },
  //fazer login do OAuth 2
  loginRequest(context, payload) {
    let service = AuthService.build();
    return new Promise((resolve, reject) => {
      service
        .login(payload.username, payload.password, payload.captcha,false, payload.isIntegration ?? null,  payload.hash ?? null)
        .then((resp) => {
          if (resp.status === false) {
            resolve(resp);
          } else {
            
            if(payload.isIntegration){
              const urlParams = new URLSearchParams();
              urlParams.set('code', resp.code);
              urlParams.set('state', resp.state);
              const url = `${payload.redirect_url}?${urlParams.toString()}`;
              window.location.href = url;
            }
            else{
              let access_token = "Bearer " + resp.access_token;
              Cookies.set("access_token", access_token, { expires: 365 });
              context.commit("authSuccess", access_token);
              context.dispatch("userRequest");
              context.dispatch("clubVerification");
              resolve({ access_token, status: resp.status });
            }
            
          }
        })
        .catch((err) => {
          context.commit("authError", err);
          Cookies.remove("access_token");
          reject(err);
        });
    });
  },
  authRequestWhitUser(context, payload) {
    return new Promise((resolve, reject) => {
      let service = AdminLoginService.build();

      let data = {
        user_id: payload.user_id,
      };
      service
        .create(data)
        .then((resp) => {
          let access_token = "Bearer " + resp.access_token;
          Cookies.set("access_token", access_token, {
            expires: 365,
          });

          context.commit("authSuccess", access_token);
          context.dispatch("adminUserRequest");
          resolve(access_token);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async authGreennEnvios() {
    const serviceEnvios = AuthServiceEnvios.build();
    return await serviceEnvios.toClub();
  },

  logoutRequest: ({ commit }) => {
    // commit("authLogout");
    commit("userLogout");
    window.$crisp.push(["do", "chat:hide"]);
    
    if (window.isWebView == true) {
      window.flutter_inappwebview.callHandler('logoutUserHandler', { logout: true });
    }
  },
};
