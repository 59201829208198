import actions from '@/store/modules/claimPublic/actions'
import getters from '@/store/modules/claimPublic/getters';
import mutations from '@/store/modules/claimPublic/mutations'

const state = {
    claim_public: {},
    claim_public_filters: {},
}

export {
    state,
    getters,
    actions,
    mutations,
}