import { render, staticRenderFns } from "./ModalHeader.vue?vue&type=template&id=313e6628&scoped=true"
import script from "./ModalHeader.vue?vue&type=script&setup=true&lang=js"
export * from "./ModalHeader.vue?vue&type=script&setup=true&lang=js"
import style0 from "./ModalHeader.vue?vue&type=style&index=0&id=313e6628&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "313e6628",
  null
  
)

export default component.exports