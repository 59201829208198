import store from '@/store/index'

function isAuthenticated(to, from, next) {
  if(store.getters.isAuthenticated && to.name !== 'VerificaEmail') {
    if(store.state.user.user) {
      if(!store.state.app.isClient && store.state.user.user.type !== 'CLIENT') {
        return next()+window.location.search
      }
    }
  }

  if (store.getters.isAuthenticated) {
    return next()
  }

  return next(`/${window.location.search}`)
}
const authentication = isAuthenticated

export default authentication