import Rest from '@/services/Rest';

/**
 * @typedef {AdminLoginService}
 */
export default class AdminLoginService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/admin-login'
}
