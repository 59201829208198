<script setup>
import { inject } from "vue";
const toggleSubDropdown = inject("toggleSubDropdown");

if (!toggleSubDropdown) {
  throw new Error("toggleSubDropdown not provided");
}
</script>

<template>
  <div class="gr-drop-sub-trigger" @click="toggleSubDropdown">
    <slot />
  </div>
</template>

<style scoped lang="scss">
.gr-drop-sub-trigger {
  width: var(--w-full);
}
</style>
