import IntegrationXService from '../services/resources/IntegrationX';
const serviceIntegrationX = IntegrationXService.build();

import PingService from '@/services/resources/PingService';
const pingService = PingService.build();

const integrationX = async (data = {}, executePing = false ) => {
  if(executePing){
    await pingService.create();
  }
  return await serviceIntegrationX.createId(data);
}
export default integrationX
