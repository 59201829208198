import store from '@/store/index'

function isAuthenticated(to, from, next) {
  if (store.getters.isAuthenticated) {
    if (to.query.type) {
      if (to.query.type === 'club') {
        return next('/dashboard?type=club')
      } else {
        return next('/dashboard?type=envios')
      }
    } else {
      return next(`/dashboard${window.location.search}`)
    }
  }

  return next()
}

const unauthentication = isAuthenticated

export default unauthentication