import Rest from '@/services/Rest';

/**
 * @typedef {SubUsersService}
 */
export default class SubUsersService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/sub-user'
}