<script setup>
import { computed } from "vue";

const props = defineProps({
  weight: {
    type: String,
    default: "normal",
  },
  color: {
    type: String,
    default: "gray-500",
  },
  fontSize: {
    type: String,
    default: "base",
  },
});

const getLineHeight = computed(() => {
  switch (props.fontSize) {
    case "xs":
      return "18px";
    case "sm":
      return "24px";
    default:
      return "24px";
  }
});
</script>

<template>
  <p
    :style="`
      font-size: var(--font-${props.fontSize});
      font-weight: var(--weight-${props.weight});
      color: var(--${props.color});
      line-height: ${getLineHeight};
    `"
  >
    <slot />
  </p>
</template>

<style lang="scss" scoped>
p {
  transition: var(--transition);
}
</style>
