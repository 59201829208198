import Rest from '@/services/Rest';

/**
 * @typedef {IntegrationXService}
 */
export default class IntegrationXService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/greenn-sales/'
}
