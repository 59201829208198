import Rest from '@/services/Rest';

/**
 * @typedef {Claim}
 */
export default class Claim extends Rest {
  /**
   * @type {String}
   */

  static resource = '/claim-seller'
}
