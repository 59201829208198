import SaleExpressService from "@/services/resources/SaleExpressService";

const state = {
  active_sessions: null,
  enabled: null
}

const getters = {
  is_sale_express_enabled: state => state.enabled
}

const mutations = {
  setActiveSessions: (state, value) => {
    state.active_sessions = value
  },
  setEnabled: (state, value) => {
    state.enabled = value
  }
}

const actions = {
  refreshSaleExpressSession: async ({commit}) => {
    let service = SaleExpressService.build();
    await service
      .get('session')
      .then((response) => {
        commit('setActiveSessions', response.data.active_sessions);
        commit('setEnabled', !response.data.is_limit_reached);
      })
      .catch((err) => {
        console.error(err);
      });
  }
}

export {
  state,
  getters,
  mutations,
  actions
}
