<script setup>
import { inject } from "vue";

const close = inject("close");
const canClose = inject("canClose");

if (!close) {
  throw new Error("ModalClose must be used within a Modal component");
}
</script>

<template>
  <div v-if="canClose" @click="close">
    <slot />
  </div>
</template>
