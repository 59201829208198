import Cookies from "js-cookie"

export function textMethodType(method) {
  let t = "";
  if (method) {
    switch (method.toUpperCase()) {
      case "CREDIT-CARD": {
        t = "CREDIT_CARD";
        break;
      }
      default: {
        t = method;
        break;
      }
    }
    return t;
  } else return method;
}

export function symbolCurrency(id) {
  let t = "";
  if (id) {
    switch (id) {
      case 14: {
        t = "$";
        break;
      }
      case 13: {
        t = "$";
        break;
      }
      case 12: {
        t = "$";
        break;
      }
      case 10: {
        t = "$";
        break;
      }
      case 11: {
        t = "$";
        break;
      }
      case 9: {
        t = "$";
        break;
      }
      case 8: {
        t = "$";
        break;
      }
      case 6: {
        t = "$";
        break;
      }
      case 5: {
        t = "$";
        break;
      }
      case 7: {
        t = "\u20a1";
        break;
      }
      case 4: {
        t = "£";
        break;
      }
      case 3: {
        t = "€";
        break;
      }
      case 2: {
        t = "US$";
        break;
      }
      case 1: {
        t = "R$";
        break;
      }

      default: {
        t = id;
        break;
      }
    }
    return t;
  } else return id;
}

export function iconMethod(method) {
  let icon = "";
  switch (method) {
    case "CREDIT_CARD": {
      icon = "credit-card";
      break;
    }
    case "PIX": {
      icon = "qrcode";
      break;
    }
    case "PAYPAL": {
      icon = ["fab", "paypal"];
      break;
    }
    case "DEBITCARD": {
      icon = "credit-card"
      break;
    }
    case "BOLETO":
    case "EFT":
    case "SPEI":
    case "SENCILLITO":
    case "OXXO":
    case "EFECTY":
      icon = "barcode";
      break;
  }
  return icon;
}

export function interAccess(userId) {
  let userInterIds = process.env.USER_INTER.substring(1, process.env.USER_INTER.length - 1).split(',')
  if(userInterIds.some(uId => parseInt(uId) === userId)) {
    return true
  } else if(Cookies.get("setPanel")) {
    if(userInterIds.some(uId => parseInt(uId) === JSON.parse(Cookies.get("setPanel")))) {
      return true
    }
  }
  return false
}
