import Rest from '@/services/Rest';

/**
 * @typedef {LastMonthStatistics}
 */
export default class LastMonthStatistics extends Rest {
  /**
   * @type {String}
   */
  static resource = '/last-month-user-statistics'
}