import moment from 'moment-timezone';

function date(date) {
    return moment.tz(date, "America/Sao_Paulo").format('DD/MM/YY');
}

function datetime(date) {
    return moment.tz(date, "America/Sao_Paulo").format('DD/MM/YY HH:mm');
}

export {
    date,
    datetime
}