<script setup>
import { ref, provide, inject, watchEffect, watch } from "vue";

const dropdownSubState = inject("dropdownSubState");
const setDropdownSubState = inject("setDropdownSubState");

if (!dropdownSubState) {
  throw new Error("Dropdown state not provided");
}
if (!setDropdownSubState) {
  throw new Error("setDropdownSubState not provided");
}

const id = `dropdown-sub-${Math.random().toString(36).substr(2, 9)}`;

const dropdownSubOpen = ref(dropdownSubState[id] || false);

watch(
  dropdownSubState,
  (newValue) => {
    dropdownSubOpen.value = newValue[id];
  },
  { deep: true }
);

watchEffect(() => {
  dropdownSubOpen.value = !!dropdownSubState[id];
});

function toggleSubDropdown() {
  if (setDropdownSubState) {
    setDropdownSubState(id);
  }
}

provide("dropdownSubOpen", ref(dropdownSubOpen));
provide("toggleSubDropdown", toggleSubDropdown);
</script>

<template>
  <div :id="id" class="gr-dropdown-sub">
    <slot />
  </div>
</template>

<style scoped lang="scss">
.gr-dropdown-sub {
  width: var(--w-full);
  position: relative;
}
</style>
