
<template>
  <keep-alive>
    <div>
      <div>
        <h2>{{ $t('client.presentation_video') }}</h2>
        <p class="text-justify">
          {{ $t('client.text_22') }}
          <a href="https://youtube.com/shorts/pqwzVCuGOpU?feature=share" target="_blank">{{ $t('client.text_23') }}</a>,
          {{ $t('client.text_24') }}
        </p>
        <p class="text-justify">
          {{ $t('client.text_23_1') }}
        </p>
        <b-form-file
          class="mt-3"
          id="video_upload"
          name="video_upload"
          plain
          v-model="video_upload"
          accept="video/*"
          @change="changeFile"
        ></b-form-file>
        <p class="text-center">{{ $t('client.text_25') }}</p>
      </div>
      <div class="actions mt-3">
        <BaseButton variant="info2" @click="onSubmit" :disabled="!video_upload"
          >{{ $t('client.text_26') }}</BaseButton
        >
      </div>
    </div>
  </keep-alive>
</template>

<script>
import { datadogLogs } from "@datadog/browser-logs";

if (['production', 'staging'].includes(process.env.VUE_APP_ENVIRONMENT) && process.env.VUE_APP_LOG_STATUS == 'enabled') {
  datadogLogs.init({
    applicationId: 'd7aa09b9-670c-4509-9c91-bfda49d83380',
    clientToken: 'pubeabdfa088f28729b07bb5e263149bc5b',
    site: 'datadoghq.com',
    service: 'adm',
    env: 'production',
    version: '1.0.0',
    sampleRate: 100,
    replaySampleRate: 100,
    defaultPrivacyLevel: 'mask-user-input',
  })
}
export default {
  data() {
    return {
      video_upload: null,
    };
  },

  methods: {
    changeFile(e) {
      const file = e.target.files[0]
 
      var duration = undefined
      var video = document.createElement('video')
      var that = this
      video.preload = 'metadata'
      video.onloadedmetadata = function() {
        window.URL.revokeObjectURL(video.src)        

        var videoSize = that.video_upload.size;
        const maxSize =  100 * 1024 * 1024;      
        if (videoSize && (videoSize > maxSize)) {
          that.$bvToast.toast(`${that.$t('app.video_1')} ${(videoSize/1000000).toFixed(2)} ${that.$t('app.video_2')}`, {
            title: `${that.$t('app.video_3')}`,
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: true
          })
          that.video_upload = null
          return
        }            
        
        duration = video.duration
        if(duration && duration > 45) {
          that.$bvToast.toast(`${that.$t('app.video_4')} ${duration.toFixed(2)} ${that.$t('app.video_5')}`, {
            title: `${that.$t('app.video_3')}`,
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: true
          })
          that.video_upload = null
          return
        }

        that.video_upload = e.target.files[0]
      }
      video.src = URL.createObjectURL(file)
    },
    onSubmit() {
      //  log video gravado
      if (['production', 'staging'].includes(process.env.VUE_APP_ENVIRONMENT) && process.env.VUE_APP_LOG_STATUS == 'enabled') {
        datadogLogs.logger.warn("🟡 Dados do Vídeo - VÍDEO GRAVADO", {
          name: "Dados na etapa do video gravado",
          dados: this.video_upload,
        });
      }
      this.$emit("confirm", this.video_upload);
    },
  },
};
</script>

<style scoped>
p {
  color: #81858e;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  margin: 0;
}
h2 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 25px;
}

.actions {
  margin: 0 auto;
  margin-top: 40px;
  display: flex;
  gap: 20px;
}
.actions button {
  flex: 1;
}
</style>
