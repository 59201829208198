<script setup>
import { inject } from "vue";

const dropdownSubOpen = inject("dropdownSubOpen");
const dropdownSubAlign = inject("alignSubDropDown");

if (!dropdownSubOpen) {
  throw new Error("dropdownSubOpen not provided");
}

if (!dropdownSubAlign) {
  throw new Error("dropdownSubAlign not provided");
}
</script>

<template>
  <Transition name="gr-v">
    <div
      v-show="dropdownSubOpen"
      class="gr-drop-sub-content"
      :class="`align-${dropdownSubAlign}`"
    >
      <slot />
    </div>
  </Transition>
</template>

<style scoped lang="scss">
.gr-drop-sub-content {
  height: min-content;
  user-select: none;
  min-width: min-content;
  position: absolute;
  // left: 100%;
  height: min-content;
  display: flex;
  gap: var(--spacing-1-5);
  flex-direction: column;
  align-items: flex-start;
  border-radius: var(--radius-lg);
  background: var(--gray-white);
  border: 1px solid var(--gray-50);
  box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.05);
  padding: var(--spacing-1) 0 var(--spacing-1) 0;
  width: var(--w-56);
  min-width: var(--w-32);
  position: absolute;
  top: 0;

  &.align-left {
    right: 100%;

    @media (max-width: 768px) {
      right: 3%;
      top: 100%;
    }
  }

  &.align-right,
  &.align-center {
    left: 100%;

    @media (max-width: 768px) {
      left: 3%;
      top: 100%;
    }
  }
}
</style>
