<script setup>
import { ref, provide, onMounted, onUnmounted, watch } from "vue";

const GrDropDown = ref(null);
const dropdownOpen = ref(false);
const dropdownSubState = ref({});

watch(dropdownOpen, (newValue) => {
  if (!newValue) {
    dropdownSubState.value = {};
  }
});

function toggleDropdown() {
  dropdownOpen.value = !dropdownOpen.value;
}

function setDropdownSubState(key) {
  let copyOBJ = Object.assign({}, dropdownSubState.value);

  if (!(key in copyOBJ)) {
    copyOBJ[key] = false;
  }

  for (const k in copyOBJ) {
    if (k !== key) {
      copyOBJ[k] = false;
    }
  }
  copyOBJ[key] = !copyOBJ[key];

  dropdownSubState.value = copyOBJ;
}

function closeIfOutsideDropDown(event) {
  if (GrDropDown.value && !GrDropDown.value.contains(event.target)) {
    dropdownOpen.value = false;
  }
}

provide("dropdownValue", dropdownOpen);
provide("toggleDropdown", toggleDropdown);
provide("dropdownSubState", ref(dropdownSubState));
provide("setDropdownSubState", setDropdownSubState);

onMounted(async () => {
  document.addEventListener("click", closeIfOutsideDropDown);
});

onUnmounted(() => {
  document.removeEventListener("click", closeIfOutsideDropDown);
});
</script>

<template>
  <div ref="GrDropDown" class="gr-dropdown">
    <slot />
  </div>
</template>

<style scoped lang="scss">
.gr-dropdown {
  height: min-content;
  user-select: none;
  min-width: min-content;
  position: relative;
}
</style>
