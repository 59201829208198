import { onMounted, onUnmounted } from "vue";

export function useKeyboardShortcut(combination, callback) {
  function handleKeyDown(event) {
    const isCtrlPressed = event.ctrlKey || event.metaKey;
    const isMPressed = event.key === "m" || event.key === "M";

    // Checks if the esc key was pressed
    const isEscPressed = event.key === "Escape"; 

    // Checks if the key combination to open the modal was pressed
    if (isCtrlPressed && isMPressed) {
      callback();
    }

    // Checks if the Esc key was pressed to close the modal
    if (combination === "Escape" && isEscPressed) {
      callback();
    }
  }

  onMounted(() => {
    document.addEventListener("keydown", handleKeyDown);
  });

  onUnmounted(() => {
    document.removeEventListener("keydown", handleKeyDown);
  });
}
