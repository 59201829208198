import Rest from '@/services/Rest';

/**
 * @typedef {CurrencyService}
 */
export default class CurrencyService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/currency'
}
