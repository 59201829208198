import FlagsService from "@/services/resources/FlagsService";
const serviceFlags = FlagsService.build();

export default {
  async fetchFlags({commit}) {
    try {
      const res = await serviceFlags.search({url:''})
      commit('setFlags', res)
      commit('setHasSearchedFlag', true)
      return res
    } catch (err) {
      console.error(err)
      return err
    }
  },
  updateFlags({commit}, data) { commit('setFlags', data) },
}
