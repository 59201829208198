<script setup>
const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  size: {
    type: String,
    default: "lg",
  },
  title: {
    type: String,
    required: false,
    default: "",
  },
  noCloseModal: {
    type: Boolean,
    default: false,
  }
});

const emit = defineEmits(["shown", "hidden"]);

function shown() {
  "shown", true;
  emit("shown", true);
  document.querySelector("body").style = "overflow: hidden;";
}

function hidden() {
  emit("hidden");
  document.querySelector("body").style = "overflow: auto !important; ";
}
</script>

<template>
  <b-modal
    :id="name"
    :size="size"
    :title="title"
    @shown="shown"
    @hidden="hidden"
    data-modal="GrModalBootstrap"
    :no-close-on-backdrop="noCloseModal"
    :no-close-on-esc="noCloseModal"
    :hide-header-close="noCloseModal"
  >
    <template v-slot:modal-header="{ cancel }">
      <slot name="header" :cancel="cancel"> </slot>
    </template>

    <slot></slot>

    <template v-slot:modal-footer="{ cancel }">
      <slot name="footer" :cancel="cancel"> </slot>
    </template>
  </b-modal>
</template>

<style lang="scss">
[data-modal="GrModalBootstrap"] {
  .modal {
    overflow: auto;

    .modal-dialog {
      margin: 0 !important;
      transform: translate(-50%, -50%) !important;
      top: 50% !important;
      left: 50% !important;

      &.modal-md .modal-footer {
        justify-content: center;

        button {
          flex: 1;
        }
      }
    }
    .modal-content {
      padding: 20px 30px !important;
      animation: showTop 0.4s forwards !important;
      border-radius: 20px !important;
      border: none !important;

      .modal-header {
        border: none !important;
        padding: 0 !important;
      }

      &:focus-visible {
        outline: none !important;
      }
    }
    .modal-content .modal-header h5 {
      font-size: 16px;
      font-weight: 600;
    }
    .modal-body {
      padding: 20px 0;
    }
    .col-auto {
      padding: 0;
    }
    .modal-content .modal-header .close {
      background: url("../../../assets/img/icons-contracts/close-modal.svg") no-repeat center center;
      overflow: hidden;
      text-indent: -100px;
      color: transparent;
      outline: none;
      border: none;
      width: 42px;
    }
    .modal-content .modal-footer {
      display: flex !important;
      align-items: center !important;
      gap: 8px !important;
      border: none !important;
      padding: 0 !important;
      padding-bottom: 25px !important;
    }
  }
}
</style>
