<script setup>
import ModalTrigger from "./ModalTrigger.vue";
import { GrTeleport } from "@/components/GrComponents";

import { ref, provide, watch } from "vue";
import { useKeyboardShortcut } from "./events";

const props = defineProps({
  close: {
    type: Boolean,
    default: true,
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
  renderTriggerTo: {
    type: String,
    default: "",
    required: true,
  },
});

const emits = defineEmits(["update:modelValue"]);

const isOpen = ref(props.modelValue);

function closeModal() {
  if (!props.close) return;
  isOpen.value = false;
  emits("update:modelValue", false);
}
useKeyboardShortcut("Escape", closeModal);

function openModal() {
  isOpen.value = true;
  emits("update:modelValue", true);
}
useKeyboardShortcut("m", openModal);

watch(
  () => props.modelValue,
  (newVal) => {
    isOpen.value = newVal;
  }
);

provide("close", closeModal);
provide("canClose", ref(props.close));
provide("open", openModal);
</script>

<template>
  <!-- Render modal in body -->
  <GrTeleport to="body">
    <!-- Render trigger in specific id -->
    <GrTeleport v-if="renderTriggerTo.length" :to="props.renderTriggerTo">
      <ModalTrigger>
        <slot name="open" />
      </ModalTrigger>
    </GrTeleport>

    <Transition>
      <div v-if="isOpen" class="modal-overlay" @click.self="closeModal">
        <slot />
      </div>
    </Transition>
  </GrTeleport>
</template>

<style scoped lang="scss">
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  animation: opacity 0.15s linear;

  background: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(1.5px);

  @include size(md) {
    align-items: end;
    height: 100dvh;
  }
}
</style>
