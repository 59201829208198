import Rest from '@/services/Rest';

/**
 * @typedef {ValidationService}
 */
export default class ValidationService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/identity-validation'
}