import Rest from '@/services/Rest';

/**
 * @typedef {SaleService}
 */
export default class SaleService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/sale-express'
}
