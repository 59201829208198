import callEvaluationService from "@/services/resources/CallEvaluationService";
const callEvaluation = callEvaluationService.build();

const crisp = {
    computed:{

        getQuerySessionIdCrisp(){
            let searchParams = new URLSearchParams(window.location.search)
            if(searchParams.has('session_id')){
               return searchParams.get('session_id');
            }
            return false
        }
    },
    methods: {
        getSessionCrisp(){
            let crispSession = document.cookie.split(';').find(item=>{
                let data = item.trim().split('=')
                if(decodeURIComponent(data[0]) === 'crisp-client/session/'+process.env.CRISP_WEBSITE_ID){
                    return item;
                }
            })
            if(!crispSession){return false;}
            return crispSession.split('=')[1];
        },
        initCrisp(){
            let notify = document.getElementById("1WZET722PJibpmL_crisp");
            if (notify === null) {
                this.configCrispWithoutUser();
                this.appendScriptCrisp()

            } else {
                const crisp = document.querySelector(".crisp-client");
                crisp.style.display = "block";
                this.configCrispWithoutUser()
            }
        },
        async checkEvaluation(WitUser = true, getCookie = false){

            await this.getEvaluation(getCookie)
                .then((resp) => {
                    if(resp.is_evaluation){
                        window.$crisp.push(["do", "session:reset"]);
                        if(WitUser){
                            this.crispWitUser()
                        }
                    }
                });
        },
        getEvaluation(getCookie = false){

            let sessionId = ''
            if(!getCookie){
                sessionId = this.getQuerySessionIdCrisp !== false ? this.getQuerySessionIdCrisp : $crisp.get("session:identifier");
            }else{
                sessionId = this.getSessionCrisp()
            }

            return callEvaluation.get('check-evaluation/?session_id='+sessionId);
        },
        configCrispWithoutUser(){
            this.crispWithoutUser()

            window.$crisp.push(["on", "message:sent", ()=>{
                callEvaluation.post('', {session_id:  $crisp.get("session:identifier"), website_id: process.env.CRISP_WEBSITE_ID, origem: 'adm'});
                $crisp.push(["off", "message:sent"])
            }])

            $crisp.push(["on", "session:loaded", async ()=>{
                await this.checkEvaluation(false);
            }])

        },
        crispWithoutUser(){
            window.$crisp = [];
            window.CRISP_WEBSITE_ID = process.env.CRISP_WEBSITE_ID;
            window.$crisp.push(["set", "user:nickname"]);
            window.$crisp.push(["set", "user:email"]);
            window.$crisp.push(["do", "chat:show"]);
            window.$crisp.push(["set", "session:segments", [["consumidor", "adm-site"]]]);
        },
        crispWitUser(){
            const dados_user = this.$store.getters.getProfile || localStorage.user;

            window.$crisp.push(["do", "chat:show"]);
            window.CRISP_WEBSITE_ID = process.env.CRISP_WEBSITE_ID;
            window.$crisp.push(["set", "user:nickname", [`@G - ${dados_user.name}`]]);
            window.$crisp.push(["set", "user:email", [`${dados_user.email}`]]);
            window.$crisp.push(["set", "user:phone", [`${dados_user.cellphone}`]]);
            window.$crisp.push(["set", "session:segments", [["greenn", "adm"]]]);
        },
        appendScriptCrisp(){
            setTimeout(() => {
                let crispScript = document.createElement("script");
                crispScript.setAttribute("src", "https://client.crisp.chat/l.js");
                crispScript.setAttribute("id", "1WZET722PJibpmL_crisp");
                document.getElementsByTagName("head")[0].appendChild(crispScript);
            }, 1000);
        }
    }
}

export default crisp

