<script setup>
import { inject } from "vue";
const toggleDropdown = inject("toggleDropdown");
</script>

<template>
  <div class="gr-drop-trigger-icon" @click="toggleDropdown">
    <slot />
  </div>
</template>

<style scoped lang="scss">
.gr-drop-trigger-icon {
  display: flex;
  gap: var(--spacing-3);
  background: transparent;
}
</style>
