import store from '@/store/index'
import UserSettingsService from '@/services/resources/UserSettingsService'
const serviceUserSettings = UserSettingsService.build()

function validationIsIndicationEnable(to, from, next) {
  const userId = store.getters.getProfile.id || JSON.parse(localStorage.user).id
  serviceUserSettings.search({url: userId, filter: ['INDICATION']})
    .then((response) => {
      if(!store.getters.setPanel) {
        if(response.some(x => x.key === 'INDICATION' && x.value === 'ENABLED')) {
          return next()
        } else {
          Cookies.set('INDICATION', JSON.stringify('DISABLED'), { expires: 365 })
          return next(`/dashboard${window.location.search}`)
        }
      } else {
        Cookies.set('INDICATION', JSON.stringify('DISABLED'), { expires: 365 })
        return next(`/dashboard${window.location.search}`)
      }
    })
    .catch(err => {
      console.error(err)
      return next(`/dashboard${window.location.search}`)
    })

}
const isIndicationEnable = validationIsIndicationEnable

export default isIndicationEnable
