<template>
  <button
    :class="['button', heigth, ...cssClasses]"
    :disabled="disabled"
    :style="customHeight"
    @click.prevent="click"
  >
    <slot v-if="!loading"> </slot>
    <!-- Loading -->
    <div v-if="loading" class="d-flex justify-content-center">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </button>
</template>
<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      required: false,
      validator: (val) =>
        [
          "primary",
          "heaven",
          "white",
          "white2",
          "danger",
          "info",
          "info-dark",
          "info2",
          "info3",
          "link-info",
          "outline-info",
          "outline-info2",
          "outline-primary",
          "outline-primary-keep",
          "outline-dark-green",
          "link",
          "outline-danger",
          "black",
          "warning",
          "outline-warning",
          "gray",
          "dark-green",
          "success"
        ].includes(val),
    },
    customHeight: {
      type: String,
      default: '',
    },
  },
  computed: {
    cssClasses() {
      let classes = [];

      this.outline
        ? classes.push(`button-outline-${this.variant}`)
        : classes.push(`button-${this.variant}`);

      return classes;
    },
    heigth() {
      switch (this.customHeight) {
        case 'small': return 'small-button-height'
        default: return ''
      }
    }
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss">
.button {
  border: none;
  background: #009488;
  color: #fff;
  outline: none !important;
  font-weight: 600;
  height: 50px;
  padding: 0 42px !important;
  border-radius: 10px;
  font-size: 14px;

  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;

  &:disabled {
    opacity: 0.7;
    pointer-events: none;
    color: rgba(0, 0, 0, 0.26);
    background-color: rgba(0, 0, 0, 0.12);
    border: none;
  }
  &:hover {
    transform: scale(1.07);
  }
  &:focus {
    box-shadow: none;
  }
}

.small-button-height {
  height: 38px !important;
}

.button.heaven {
  border: none;
  background: $base-color-heaven;
  color: #fff;
  outline: none !important;
  font-weight: 600;
  height: 50px;
  padding: 0 42px !important;
  border-radius: 10px;
  font-size: 14px;

  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;

  &:disabled {
    opacity: 0.7;
    pointer-events: none;
    color: rgba(0, 0, 0, 0.26);
    background-color: rgba(0, 0, 0, 0.12);
    border: none;
  }
  &:hover {
    transform: scale(1.07);
  }
  &:focus {
    box-shadow: none;
  }
}

.button-primary {
  color: #fff;
  background: $base-color;
}

.button-primary-inverted {
  background: #e0fcf4;
  color: $base-color;
}

.button-heaven {
  color: #fff;
  background: $base-color-heaven;
}

.button-heaven-inverted {
  background: #e0fcf4;
  color: $base-color-heaven;
}

.button-white {
  width: auto;
  color: #777;
  background: transparent;
  border: 1px #fff solid;
  cursor: pointer;

  &:hover {
    background: #777;
    color: #fff;
  }
}

.button-white2 {
  width: auto;
  color: #000;
  background: transparent;
  border: 1px #000 solid;
  cursor: pointer;

  &:hover {
    background: #fff;
    color: #000;
  }
}

.button-danger {
  color: #fff;
  background: #ff0c37;
  &:hover {
    box-shadow: 0 8px 20px rgba(255, 12, 55, 0.2);
  }
}

.button-warning {
  color: #000;
  background: #FF9E44;
  &:hover {
    box-shadow: 0 8px 20px rgba(252, 226, 156, 0.2);
  }
}

.button-info-dark {
  width: auto;
  color: #fff;
  background: #002092;
  border: 1px #002092 solid;
  cursor: pointer;

  &:hover {
    box-shadow: 0 8px 20px rgba(156, 162, 252, 0.2);
  }
}
.button-info {
  width: auto;
  color: #fff;
  background: $secondary-color;
  border: 1px $secondary-color solid;
  cursor: pointer;

  &:hover {
    background: transparent;
    color: $secondary-color;
  }
}

.button-info2 {
  width: auto;
  color: #fff;
  background: $secondary-color;
  border: 1px $secondary-color solid;
  cursor: pointer;
}

.button-info3 {
  width: auto;
  color: $secondary-color;
  background: #2133d21a;
  border: none;
  cursor: pointer;
}

.button-black {
  width: auto;
  color: #fff;
  background: #020016;
  cursor: pointer;
}

.button-link-info {
  color: #333;
  background: #f7f7f7;
  border: none;
  cursor: pointer;
}
.button-link {
  color: #fff;
  background: #333;
  border: none;
  cursor: pointer;
}

// Outlines

.button-outline-primary {
  background: transparent;
  color: $base-color;
  border: 1px $base-color solid;

  &:hover {
    background: $base-color;
    color: #fff;
  }
}

.button-outline-primary-keep {
  background: transparent;
  color: $base-color;
  border: 1px $base-color solid;

  &:hover {
    background: transparent;
    color: $base-color;
  }
}

.button-outline-dark-green {
  background: transparent;
  color: #006B63;
  border: 1px #006B63 solid;

  &:hover {
    background: #006B63;
    color: #fff;
  }
}

.button-outline-white {
  background: transparent;
  color: #777;
  border: 1px #777 solid;

  &:hover {
    background: #777;
    color: #333;
  }
}

.button-outline-white2 {
  background: transparent;
  color: #fff;
  border: 1px #fff solid;

  &:hover {
    background: #fff;
    color: #333;
  }
}

.button-outline-danger {
  background: transparent;
  color: #ff0c37;
  border: 1px #ff0c37 solid;

  &:hover {
    background: #ff0c37;
    color: #fff;
  }
}

.button-outline-info {
  background: transparent;
  color: #2133d2;
  border: 1px #2133d2 solid;

  &:hover {
    background: #2133d2;
    color: #fff;
  }
}
.button-outline-info2 {
  background: transparent;
  color: #2133d2;
  border: 1px #2133d2 solid;
  background: rgba(0, 67, 146, 0.1);
}

.button-outline-warning {
  background: transparent;
  color: #FF9E44;
  border: 1px #FF9E44 solid;

  &:hover {
    background: #FF9E44;
    color: #fff;
  }
}

.button-success {
  background: transparent;
  color: #009488;
  border: 1px #009488 solid;

  &:hover {
    background: #009488;
    color: #fff;
  }
}

.button-gray {
  background: #F7F7F7;
  color: #333;

  &:hover {
    background: #e9e8e8;
    color: #333;
  }
}

.button-dark-green {
  background: #006B63;
  color: #fff;
}

.button-outline-black {
  background: transparent;
  color: #000;
  border: 1px #000 solid;

  &:hover {
    background: #000;
    color: #fff;
  }
}

@media screen and (max-width: 768px) {
  .button {
    height: 45px;
    padding: 0 20px !important;
    border-radius: 10px;
    font-size: 13px;
  }
}
</style>
