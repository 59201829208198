<script setup>
import { ref, provide } from "vue";

const activeCollapsible = ref(null);

function setActiveCollapsible(id) {
  activeCollapsible.value = id;
}

provide("activeCollapsible", activeCollapsible);
provide("setActiveCollapsible", setActiveCollapsible);
</script>

<template>
  <div class="gr-collapse">
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">
.gr-collapse {
  display: grid;
  gap: var(--spacing-2);
}
</style>
