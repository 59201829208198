<script setup>
import {
  GrDropDown,
  GrDropDownTrigger,
  GrButton,
} from "@/components/GrComponents";

import GrApps from "@/components/Side/GrSideBar/GrApps.vue";

const emit = defineEmits(["click"]);

function emitToogleSide() {
  emit("click");
}
</script>
<template>
  <nav>
    <GrButton
      variant="alternative"
      class="mobile-menu-toggle"
      @click="emitToogleSide()"
    >
      <svg
        xml
        ns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 256 256"
      >
        <path
          d="M228,128a12,12,0,0,1-12,12H40a12,12,0,0,1,0-24H216A12,12,0,0,1,228,128ZM40,76H216a12,12,0,0,0,0-24H40a12,12,0,0,0,0,24ZM216,180H40a12,12,0,0,0,0,24H216a12,12,0,0,0,0-24Z"
        />
      </svg>
    </GrButton>
    <GrDropDown>
      <GrDropDownTrigger>
        <GrButton variant="alternative" class="apps-toggle-button">
          <svg
            width="18"
            height="18"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.80769 8.61539C6.91062 8.61539 8.61539 6.91062 8.61539 4.80769C8.61539 2.70477 6.91062 1 4.80769 1C2.70477 1 1 2.70477 1 4.80769C1 6.91062 2.70477 8.61539 4.80769 8.61539ZM15.1923 8.61539C17.2953 8.61539 19 6.91062 19 4.80769C19 2.70477 17.2953 1 15.1923 1C13.0894 1 11.3846 2.70477 11.3846 4.80769C11.3846 6.91062 13.0894 8.61539 15.1923 8.61539ZM4.80769 19C6.91062 19 8.61539 17.2953 8.61539 15.1923C8.61539 13.0894 6.91062 11.3846 4.80769 11.3846C2.70477 11.3846 1 13.0894 1 15.1923C1 17.2953 2.70477 19 4.80769 19ZM15.1923 19C17.2953 19 19 17.2953 19 15.1923C19 13.0894 17.2953 11.3846 15.1923 11.3846C13.0894 11.3846 11.3846 13.0894 11.3846 15.1923C11.3846 17.2953 13.0894 19 15.1923 19Z"
              stroke-width="1.7"
              stroke-linecap="round"
            />
          </svg>
        </GrButton>
      </GrDropDownTrigger>
      <GrApps :align="'right'" />
    </GrDropDown>
    <router-link :to="{ name: 'Dashboard' }" class="link-dashboard">
      <img
        src="@/assets/img/gr-icons/greenn.svg"
        alt="Logo Greenn"
        class="logo-black"
      />
    </router-link>
  </nav>
</template>

<style scoped lang="scss">
.container-apps {
  padding: var(--spacing-1);
  width: var(--w-full);
  .apps {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;

    .app {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.link-dashboard {
  border-radius: var(--radius);
}

.logo-black {
  height: var(--h-10);
  @media (max-width: 768px) {
    height: var(--h-8);
  }
}
.apps-toggle-button {
  padding: 0;
  aspect-ratio: 1;
  margin-right: var(--spacing-3);
  border-radius: 50%;
  svg {
    stroke: var(--primary-500);
  }
  @media (max-width: 768px) {
    display: none;
  }
}
.mobile-menu-toggle {
  display: none;
  padding: 0;
  aspect-ratio: 1;
  margin-right: var(--spacing-3);
  background-color: transparent !important;
  user-select: none;
  svg {
    fill: var(--primary-500);
  }
  @media (max-width: 768px) {
    display: flex;
  }
}
nav {
  display: flex;
  align-items: center;
}
</style>
