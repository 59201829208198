<script setup>
import { computed } from "vue";

const props = defineProps({
  variant: {
    type: String,
    default: "success",
  },
  customBackground: {
    type: String,
    default: "",
  },
  borderFull: {
    type: Boolean,
    default: false,
  },
  padding: {
    type: String,
    default: "",
  },
});

const customBg = computed(() => {
  return props.customBackground;
});

const cssClasses = computed(() => {
  let classes = [];

  if (props.customBackground.length) {
    classes.push("custom");
  } else {
    classes.push(`${props.variant}`);
  }

  if (props.borderFull) {
    classes.push("border-full");
  }

  return classes;
});

const dynamicStyle = computed(() => {
  if (props.padding) {
    return `padding:${props.padding}px;`;
  }

  return "";
});
</script>

<template>
  <div
    :class="['gr-tag', ...cssClasses]"
    :style="dynamicStyle"
    @click="$emit('click')"
  >
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.gr-tag {
  font-size: 13px;
  font-weight: 700;
  border-radius: 10px;
  padding: 10px;
  width: fit-content;
  flex-wrap: wrap;

  &.custom {
    color: var(--grenn-secondary);
    background: v-bind(customBg);
  }

  &.border-full {
    border-radius: 50%;
  }

  &.success {
    color: var(--primary);
    background: var(--primary-light);
  }

  &.warning {
    color: var(--orange);
    background: var(--orange-light);
  }

  &.info {
    color: var(--blue);
    background: var(--blue-light);
  }

  &.danger {
    color: var(--red);
    background: var(--red-light);
  }

  &.black {
    color: var(--green-dark-50);
    background: var(--green-dark-500);
  }
}
</style>
