<script>
export default {
  props: {
    to: {
      type: String,
      required: true,
    },
  },
  mounted() {
    // Checks if the target element exists
    const targetElement = document.querySelector(this.to);
    if (targetElement) {
      // Creates a temporary container for slot contents
      this.teleportContainer = document.createElement("div");
      targetElement.appendChild(this.teleportContainer);
      // Manually render slot contents in the container
      this.teleportContainer.appendChild(this.$el);
    }
  },
  beforeDestroy() {
    // Removes the container created in the DOM
    if (this.teleportContainer && this.teleportContainer.parentNode) {
      this.teleportContainer.parentNode.removeChild(this.teleportContainer);
    }
  },
  render(h) {
    // Renders the contents of the slot inside a temporary div
    return h("div", this.$slots.default);
  },
};
</script>

<template>
  <div>
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped></style>
