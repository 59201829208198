// Services
import ProductTypesService from "@/services/resources/ProductTypes";
import ProductService from "@/services/resources/ProductService";

const TypesServices = ProductTypesService.build();
const serviceProduct = ProductService.build();

export const state = {
  product: {
    name: "",
    description: "",
    sales_page: "",
    category_id: null,
    warranty: 0,
    type: null,
    amount: null,
    trial: 0,
    is_checkout_address: false,
    method: [],
    images: [],
    links: [],
    period: 30,
    custom_period: null,
    charges: null,
  },
  types: [],
  currentType: {},
  currentTypeId: null,
  currentChecklist: [],
  currentStep: 1,
  checklists: [],
  countryCode: "BR",
  // Loading
  loading: false,
};
export const getters = {
  types: (state) => state.types,
  currentStep: (state) => state.currentStep,
  selectedTypeId: (state) => state.currentType?.id || null,
  isLoading: (state) => state.loading,
};
export const mutations = {
  setTypes(state, payload) {
    state.types = payload;
  },
  setCurrentType(state, payload) {
    state.currentType = payload;
    state.currentTypeId = payload.id;
    state.currentChecklist = state.checklists
      .filter((item) => item.type === payload.type)
      .pop();
  },
  setCurrentStep(state, payload) {
    state.currentStep = payload;
  },
  setChecklists(state) {
    state.types.forEach((type) => {
      state.checklists.push({
        type: type.type,
        steps: type.product_type_checklist.length,
        checklist: type.product_type_checklist.map((item, index) => ({
          ...item,
          title: item.type,
          description: item.description,
          isCompleted: false,
          current: false,
          step: index + 1,
        })),
      });
    });
  },
  setProduct(state, payload) {
    state.product = { ...state.product, ...payload };
  },
  setBaseAmount(state, payload) {
    state.product.amount = payload;
  },
  setCountryCode(state, payload) {
    state.countryCode = payload;
  },
  resolveCurrentChecklist(state) {
    if (!state?.currentChecklist?.checklist?.length) return;
    state.currentChecklist.checklist = state.currentChecklist.checklist.map(
      (item) => {
        if (state.currentStep < item.step) {
          item.isCompleted = false;
          item.current = false;
        }
        if (state.currentStep > item.step) {
          item.isCompleted = true;
          item.current = false;
        }
        if (state.currentStep === item.step) {
          item.current = true;
          item.isCompleted = false;
        }
        return item;
      }
    );
  },
  resetProduct(state) {
    state.product = {
      name: "",
      description: "",
      sales_page: "",
      category_id: null,
      warranty: 0,
      type: null,
      amount: null,
      trial: 0,
      is_checkout_address: false,
      method: [],
      images: [],
      links: [],
      period: 30,
      custom_period: null,
      charges: null,
    };
  },
  // Loading
  setLoading(state, payload) {
    state.loading = payload;
  },
};
export const actions = {
  async fetchTypes({ state, commit }) {
    commit("setLoading", true);
    await TypesServices.read({ id: `?country_code=${state.countryCode}` })
      .then((res) => {
        commit("setTypes", res);
        commit("setChecklists");
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  async fetchProduct({ state, commit }, id) {
    commit("setLoading", true);
    const data = {
      id: `adm/${id}?country_code=${state.countryCode}`,
    };
    return await serviceProduct
      .read(data)
      .then((res) => {
        commit("setProduct", res.data);
        return res.data;
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  async fetchOffers({ commit, state }) {
    return await serviceProduct
      .read({ id: state.product.id + "/offers" })
      .then((response) => {
        commit(
          "setBaseAmount",
          response.filter((item) => item.default).pop()?.amount || 0
        );
        return response;
      });
  },
  async updateProduct({ commit, state }, payload) {
    commit("setLoading", true);
    payload = {
      ...payload,
      sales_page:
        payload.sales_page ||
        state.product.links.filter((item) => item.name === "MAIN")[0].url,
    };
    console.log(payload)
    return await serviceProduct.update(payload).finally(() => {
      commit("setLoading", false);
    });
  },
};
