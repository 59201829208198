<script setup>
import { inject } from "vue";

const close = inject("close");
const canClose = inject("canClose");

if (!close) {
  throw new Error("ModalClose must be used within a Modal component");
}
</script>

<template>
  <header>
    <h1>
      <slot />
    </h1>

    <button v-if="canClose" @click="close">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="#000000"
        viewBox="0 0 256 256"
      >
        <path
          d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"
        />
      </svg>
    </button>
  </header>
</template>

<style scoped lang="scss">
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  margin-bottom: 470px;
  width: 90%;

  h1 {
    font-size: 24px;
  }

  button {
    outline: none;

    height: 28px;
    width: 28px;

    border-radius: 999px;
    border: none;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
  }
}
</style>
