<script setup>
import { inject } from "vue";
const toggleDropdown = inject("toggleDropdown");
const sizeDropDown = inject("sizeDropDown");

const props = defineProps({
  to: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["click"]);

function emitClick() {
  toggleDropdown();
  emit("click");
}
</script>

<template>
  <span
    @click="emitClick"
    :class="`size-${sizeDropDown}`"
    class="gr-drop-item-link"
    tabindex="0"
  >
    <router-link :to="{ name: props.to }" tabindex="1">
      <slot />
    </router-link>
  </span>
</template>

<style scoped lang="scss">
.gr-drop-item-link {
  background: transparent;
  cursor: pointer;
  border-radius: var(--radius-md);
  padding: var(--spacing-1-5) var(--spacing-2) var(--spacing-1-5)
    var(--spacing-2);
  width: var(--w-full);
  transition: var(--transition);
  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: var(--spacing-3);
  }

  &.size-sm {
    padding: var(--spacing-1) var(--spacing-1-5) var(--spacing-1)
      var(--spacing-1-5);
  }

  &.size-md {
    padding: var(--spacing-1-5) var(--spacing-2) var(--spacing-1-5)
      var(--spacing-2);
  }

  &.size-lg {
    padding: var(--spacing-2) var(--spacing-1-5) var(--spacing-2)
      var(--spacing-1-5);
  }

  &:focus-visible {
    transition: none;
    outline: 0px solid transparent;
    background: rgb(229, 231, 231, 0.35);
  }

  &:hover {
    background: rgb(229, 231, 231, 0.35);
  }
}
</style>
