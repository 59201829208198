import Rest from '@/services/Rest';

/**
 * @typedef {CallEvaluationService}
 */
export default class CallEvaluationService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/call-evaluation'
}
