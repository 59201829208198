import store from '@/store/index'

function isAmbassador(to, from, next) {
  if (!!store.state.user.user.is_ambassador) {
    return next()
  }

  return next(`/${window.location.search}`)
}
const ambassador = isAmbassador

export default ambassador
