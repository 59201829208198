import actions from '@/store/modules/claim/actions'
import getters from '@/store/modules/claim/getters';
import mutations from '@/store/modules/claim/mutations'
import moment from 'moment'

const state = {
    answer: {},
    answers: [],
    claim: {},
    claims: {
        data: []
    },
    claim_filters: {},
    modal_view_claim: false,
    locale_data: {
        direction: "ltr",
        format: "dd/mm/yyyy",
        separator: " - ",
        applyLabel: "Aplicar",
        cancelLabel: "Cancelar",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
        monthNames: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
        ],
        firstDay: 0,
    },
    date_range: {
        startDate: moment(),
        endDate: moment(),
    },
    date_ranges: () => {
        let today = new Date();
        today.setHours(0, 0, 0, 0);

        let yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);
        yesterday.setHours(0, 0, 0, 0);

        let lastWeek = new Date();
        lastWeek.setDate(today.getDate() - 7);
        lastWeek.setHours(0, 0, 0, 0);

        return {
            Hoje: [today, today],
            Ontem: [yesterday, yesterday],
            "Última semana": [lastWeek, today],
            "Este mês": [
                new Date(today.getFullYear(), today.getMonth(), 1),
                new Date(today.getFullYear(), today.getMonth(), 30),
            ],
            "Este ano": [
                new Date(today.getFullYear(), 0, 1),
                new Date(today.getFullYear(), 11, 31),
            ],
            "Último mês": [
                new Date(today.getFullYear(), today.getMonth() - 1, 1),
                new Date(today.getFullYear(), today.getMonth(), 0),
            ],
        };
    },
}

export {
    state,
    getters,
    actions,
    mutations,
}