var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(_setup.GrSidebar,[_c(_setup.GrSideHeader,{ref:"GrSideHeaderRef",attrs:{"isAmbassador":!!_setup.store.state.user.user.is_ambassador},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c(_setup.GrSidebarHeaderLeft,{on:{"click":_setup.toogleSide}})]},proxy:true},{key:"right",fn:function(){return [_c(_setup.GrSidebarHeaderRight)]},proxy:true}])}),_c('section',{ref:"GrSidebarRef",class:{ activeSidebar: _setup.activeSidebar },style:({ top: _setup.distanceFromTop }),on:{"mouseleave":_setup.closeSideMenu,"mouseenter":_setup.openSideMenu}},[(
          !_setup.store.getters.setPanel || (_setup.store.getters.setPanel && _setup.dashboard)
        )?_c(_setup.GrSideItem,{attrs:{"name":"Dashboard","route":"Dashboard","active":_setup.activeDashboard,"hover":_setup.hovered},on:{"click":function($event){_setup.setItemActive,
            _setup.setSubItemActive({
              name: 'Dashboard',
              route: 'Dashboard',
              active: false,
              item: 'Dashboard',
            })}}},[_c(_setup.iconSideDashboard,{staticClass:"icons-dropdown",attrs:{"active":_setup.activeDashboard}})],1):_vm._e(),(!_setup.isClient && !_setup.isEmptySales)?_c(_setup.GrSideItemDrop,{attrs:{"name":"Vendas","traduction":"side.text_1952","active":_setup.activeItems.Vendas,"open":_setup.openItems.Vendas,"routes":_setup.routesSales,"hover":_setup.hovered},on:{"changeItem":function($event){return _setup.setItemActive($event)},"changeSubItem":_setup.setSubItemActive}},[_c(_setup.iconSideSales,{staticClass:"icons-dropdown",attrs:{"active":_setup.isActiveDashboard,"open":_setup.openItems.Vendas}})],1):_vm._e(),(!_setup.isClient && !_setup.isEmptyProducts)?_c(_setup.GrSideItemDrop,{attrs:{"name":"Produtos","traduction":"side.text_1954","active":_setup.activeItems.Produtos,"open":_setup.openItems.Produtos,"routes":_setup.routesProducts,"hover":_setup.hovered},on:{"changeItem":function($event){return _setup.setItemActive($event)},"changeSubItem":_setup.setSubItemActive}},[_c(_setup.iconSideProducts,{staticClass:"icons-dropdown",attrs:{"active":_setup.activeItems.Produtos,"open":_setup.openItems.Produtos}})],1):_vm._e(),(!_setup.isClient && !_setup.isEmptyFinancial)?_c(_setup.GrSideItemDrop,{attrs:{"name":"Financeiro","traduction":"side.text_1988","active":_setup.activeItems.Financeiro,"open":_setup.openItems.Financeiro,"routes":_setup.routesFinancial,"hover":_setup.hovered},on:{"changeItem":_setup.setItemActive,"changeSubItem":_setup.setSubItemActive}},[_c(_setup.iconSideFinancial,{staticClass:"icons-dropdown",attrs:{"active":_setup.activeItems.Financeiro,"open":_setup.openItems.Financeiro}})],1):_vm._e(),(!_setup.isClient && !_setup.store.getters.setPanel)?_c(_setup.GrSideItem,{attrs:{"name":"Relatórios","traduction":"side.text_1989","route":"reports","active":_setup.activeRelatorio,"hover":_setup.hovered},on:{"click":function($event){_setup.setItemActive,
            _setup.setSubItemActive({
              name: 'Relatórios',
              route: 'reports',
              active: false,
              item: 'Relatórios',
            })}}},[_c(_setup.iconSideReports,{staticClass:"icons-dropdown",attrs:{"active":_setup.activeRelatorio}})],1):_vm._e(),(!_setup.isClient && !_setup.isEmptyRewards && !_setup.store.getters.setPanel)?_c(_setup.GrSideItemDrop,{attrs:{"name":"Recompensas","traduction":"side.text_2003","active":_setup.activeItems.Recompensas,"open":_setup.openItems.Recompensas,"routes":_setup.routesRewards,"hover":_setup.hovered},on:{"changeItem":_setup.setItemActive,"changeSubItem":_setup.setSubItemActive}},[_c(_setup.iconSideRewards,{staticClass:"icons-dropdown",attrs:{"active":_setup.activeItems.Recompensas,"open":_setup.openItems.Recompensas}})],1):_vm._e(),(!_setup.isClient && !_setup.isEmptySystem && !_setup.store.getters.setPanel)?_c(_setup.GrSideItemDrop,{staticClass:"last-icon-padding-bottom",attrs:{"name":"Sistema","traduction":"side.text_1990","active":_setup.activeItems.Sistema,"open":_setup.openItems.Sistema,"routes":_setup.routesSystem,"hover":_setup.hovered},on:{"changeItem":_setup.setItemActive,"changeSubItem":_setup.setSubItemActive}},[_c(_setup.iconSideSystem,{staticClass:"icons-dropdown",attrs:{"active":_setup.activeItems.Sistema,"open":_setup.openItems.Sistema}})],1):_vm._e()],1)],1),_c('main',{staticClass:"main",class:{ mobile: _setup.isMobile }},[_c('transition',{attrs:{"mode":"out-in"}},[_c('router-view')],1)],1),_c('div',{staticClass:"hide-sidebar-mobile",on:{"click":function($event){return _setup.toogleSide()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }