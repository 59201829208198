import store from '@/store/index'

function validationIsSeller(to, from, next) {
  if(store.state.user.user.type === 'SELLER') {
    return next()
  }

  return next(`/dashboard${window.location.search}`)
}
const isSeller = validationIsSeller

export default isSeller
