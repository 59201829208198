import Rest from '@/services/Rest';

/**
 * @typedef {RecoveryHashService}
 */
export default class RecoveryHashService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/recovery-hash'
}
