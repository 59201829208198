import Rest from '@/services/Rest';

/**
 * @typedef {PingService}
 */
export default class PingService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/ping'
}
