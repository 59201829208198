function validationIsHeaven(to, from, next) {
  if(!window.location.href.includes(process.env.VUE_ADM_HEAVEN_PAGE)) {
    return next()
  }

  return next(`/dashboard${window.location.search}`)
}
const isHeaven = validationIsHeaven

export default isHeaven
