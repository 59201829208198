import Rest from '@/services/Rest';

/**
 * @typedef {AffiliateService}
 */
export default class AffiliateService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/affiliate'
}
